import React from "react";
import { useStaticQuery, graphql, PageProps } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostList from "../components/PostList";
import PageHeader from "../components/PageHeader";
import {
  Connection,
  MarkdownFileNode,
  PostFrontmatter,
  QueryFragment,
} from "../models";

const PostsPage: React.FC<PageProps> = () => {
  const posts = useStaticQuery<PostsPageQueryResult>(graphql`
    query PostsPageQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "posts" }
          childMarkdownRemark: { frontmatter: { draft: { ne: true } } }
          extension: { eq: "md" }
        }
        sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                tags
                language
                date(formatString: "MMM DD, YYYY")
              }
              id
            }
          }
        }
      }
    }
  `).allFile.edges.map((x) => ({ node: x.node.childMarkdownRemark }));
  return (
    <Layout>
      <SEO title="Posts" />
      <PageHeader title="Posts" />
      <PostList posts={posts} />
    </Layout>
  );
};

PostsPage.displayName = "PostsPage";

export default PostsPage;

type PostsPageQueryResult = QueryFragment<
  "allFile",
  Pick<Connection<MarkdownFileNode<PostFrontmatter>>, "edges">
>;
